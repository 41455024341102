import React from "react";
import { Link } from "gatsby";
import Grid from "@material-ui/core/Grid";
import { Box, Button, IconButton, Collapse } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import MuiLink from "@material-ui/core/Link";
import Hidden from "@material-ui/core/Hidden";

import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import GenericAdForm from "../components/Form/genericAdForm";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import HorizontalLogo from "../images/v2/campaigns/Horizontal-logo.png";
import Logo from "../images/v2/PC-vertical-logo.svg";
import callImg from "../images/v2/campaigns/call.svg";
import Divider from "@material-ui/core/Divider";
import singaporeSmeBannerImg from "../images/v2/aircall-dashboard.png";
import singaporeSmeSalesCRMImg from "../images/v2/contentImage2x.png";
import formSuccessImg from "../images/v2/campaigns/form-success-img.svg";
import useWidth from "../hooks/useWidth";
import requestDemoBGImg from "../images/v2/campaigns/request-demo-bg.svg";
import WhiteButton from "../components/v2/WhightButton";
import IntegratedCRMBgImg from "../images/v2/home/integrated-crm-bg.svg";
import SEO from "../components/SEO";

import { StaticImage } from "gatsby-plugin-image";

const useStyles = makeStyles((theme) => ({
  marginAuto: {
    margin: "0 auto",
  },
  header: {
    boxShadow: "0 2px 4px 0 rgba(0,0,0,.29)",
    padding: ".65rem 0 .25rem",
  },
  backgroundWhite: {
    position: `relative`,
    backgroundColor: `#fff7e0`,
  },
  description: {
    marginTop: "-16px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "8px",
      marginTop: 0,
    },
  },
  contentIntegrated: {
    textAlign: "-webkit-center",
  },

  whatsappBackground: {
    textAlign: "-webkit-center",
  },
  singaporeSmeBanner: {
    position: `relative`,
    background: `#fff url(${singaporeSmeBannerImg}) no-repeat center`,
    backgroundSize: "cover",
    width: "100%",
    [theme.breakpoints.up(1600)]: {
      position: `relative`,
      background: `#fff url(${singaporeSmeBannerImg}) no-repeat center`,
      backgroundSize: "cover",
      width: "100%",
    },
  },

  listItem: {
    paddingLeft: "0px",
  },
  listItemText: {
    "& *": {
      fontSize: 18,
      color: "#2e3f4f",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
  },
  listItemIcon: {
    color: "#fff",
    fontSize: 26,
    backgroundColor: "#15a323",
    borderRadius: "50%",
    padding: "5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
    },
  },

  img: {
    backgroundSize: "contain",
  },
  nopad: {
    paddingLeft: "0",
    paddingTop: "0",
  },
  nopadtpbtm: {
    paddingTop: "0",
    paddingBottom: "0",
  },

  whatsappContainerMargin: {
    marginLeft: "100px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0",
    },
  },
  singaporeSmeSalesCRMImg: {
    marginTop: "0px",
    maxWidth: "953px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
      maxWidth: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "0",
      maxWidth: "100%",
    },
  },
  cardHeader: {
    background: "#f2f7fd",
    marginLeft: 54,
  },
  landingbtn: {
    padding: "10px 45px",
    fontSize: "22px",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      padding: "10px 35px",
      fontSize: "18px",
    },
  },
  IntegratedCRMBg: {
    background: `url(${IntegratedCRMBgImg}) center bottom no-repeat`,
    backgroundSize: "cover",
  },
  whitespaceNowrap: {
    whiteSpace: "nowrap",
  },
  pepperCloudCRMHelps: {
    [theme.breakpoints.up("md")]: {
      backgroundSize: "100%",
      backgroundPosition: "center",
    },
  },
  pepperCloudCRMHelpsSpan: {
    [theme.breakpoints.up("md")]: {
      backgroundColor: "#ffffff",
      padding: "0 1rem",
    },
  },
  pepperCloudCRMHelpsContent: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "#ffffff",
    padding: "20px 0 20px 0",
    textAlign: "-webkit-center",
  },
  aircallLogo: {
    textAlignLast: "center",
  },
  aircallLogoImg: {
    maxWidth: "280px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "210px",
      marginTop: "-30px",
    },
  },
  integrationContainer: {
    padding: "30px 40px",
  },
  pepperCloudCRMHelpsContentListBox: {
    padding: "0rem 1rem",
  },
  pepperCloudCRMHelpsContentList: {
    listStyle: "none",
    padding: "0px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  pepperCloudCRMHelpsContentListItem: {
    display: "inline-block",
    paddingRight: "2rem",
    marginRight: "2rem",
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      borderRight: "none !important",
    },
  },
  copyFooter: {
    color: "#cbcccd",
    textAlign: "end",
    paddingRight: "23px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  yellowImageLine: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
  pepperCloudCRMHelpsContentListItemSpan: {
    color: "#e2ab10",
    fontSize: "61px",
    fontWeight: "600",
    lineHeight: "normal",
    fontFamily: "Poppins",
  },
  expendButton: {
    backgroundColor: "#eaf0f6",
    "&:hover": {
      background: "#a1dae2",
    },
  },
  nopadtpbtm: {
    paddingTop: "0",
    paddingBottom: "0",
  },
  nopad: {
    paddingLeft: "0",
    paddingTop: "0",
  },
  WhatsAppCrm: {
    background: "linear-gradient(1deg, #dbe9f9 30%, #fff 117%)",
    paddingBottom: "35px",
  },
  whatsappContainerMargin: {
    marginLeft: "0px",
    paddingLeft: "94px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
      paddingLeft: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0",
      paddingLeft: "0px",
    },
  },

  requestDemoRoot: {
    background: `url(${requestDemoBGImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  cycleProcessImg: {
    "& picture img": {
      willChange: "auto !important",
    },
    // maxWidth: "530px",
    // height: "auto",
  },

  aircallConatiner: {
    paddingLeft: "359px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },

  smeSupportLogo: {
    width: "100%",
    padding: "0 17px",
    [theme.breakpoints.down("xs", "sm")]: {
      width: "100%",
      padding: "0 ",
    },
  },

  listHeading: {
    paddingLeft: "0px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0",
      marginTop: "-70px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
    },
  },

  smeSupportImg: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "auto",
      padding: "16px",
    },
  },
  homeSliderText: {
    [theme.breakpoints.down("xs")]: {
      textAlignLast: "center",
    },
  },
  mainHeading: {
    width: "525px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      textAlign: "center",
    },
  },
  logoImg: {
    marginLeft: "224px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0px",
      marginTop: "-75px",
      marginBottom: "25px",
    },
  },
  aircallSubheading: {
    padding: "0 6px",
  },
  subHeading: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
}));

const aboutAircall = [
  { title: "Easy and quick integration" },
  { title: "User-friendly interface" },
  { title: "High-quality voice calls" },
  { title: "Built-in call recording" },
];
const security =
  `Initiate outbound calls with a simple click, eliminating the need for manually typing the phone number.
  Get an overview of caller details prior to connecting with your customers, and tailor your response.
  Automatically log call details such as duration and call recording and associate them with the respective profiles.  
  Add notes and tags to calls, keeping a comprehensive history of interactions.
  Analyse your team’s call metrics in real time through the CRM dashboard and make data-driven decisions.
  Predefine call distribution workflows to intelligently route inbound calls to the appropriate teams. 
  If the calls go unanswered, send a WhatsApp message through CRM and keep the conversation going.`
    .split(`
`);

const Aircall = () => {
  const myRef = React.useRef(null);

  const executeScroll = () => {
    myRef.current.scrollIntoView();
  };

  const classes = useStyles();

  const width = useWidth();

  return (
    <React.Fragment>
      <SEO
        canonical="/aircall-crm-integration"
        description="Automate sales with Pepper Cloud CRM with popular VoIP/Phone system - Aircall integration. Enable instant voice calling, add notes or tags, and more directly from CRM"
        keywords="aircall integrations,Aircall/VoIP/Phone System and CRM Integration"
        pathname="/aircall-crm-integration"
        title="Aircall/VoIP/Phone System and CRM Integration | Pepper Cloud"
      />

      {/* Banner */}
      <Box className={classes.singaporeSmeBanner}>
        <Container>
          <Grid alignItems="center" container justify="center" spacing={7}>
            {/*<Box alignItems="center" component={Grid} display="flex" flexDirection="column" item md={6} sm={12}>*/}
            <Grid item lg={5} md={5} sm={12}>
              <Box className={classes.homeSliderText}>
                <HeaderTypography
                  component="h1"
                  fontSize={40}
                  fontWeight={600}
                  mt={0}
                  mb={2}
                  pt={10}
                  maxWidth={700}
                  className={classes.mainHeading}
                >
                  Initiate instant voice calls with Aircall integration
                </HeaderTypography>

                <ParagraphTypography
                  mb={5}
                  mt={0}
                  className={classes.subHeading}
                >
                  Empower your pre-sales, sales, and support team to efficiently
                  manage inbound and outbound calls, effortlessly access caller
                  information, and keep track of conversations, right from the
                  CRM.
                </ParagraphTypography>
                <Box className={classes.getStartedBtn} mb={7}>
                  <Button
                    className={classes.customButton}
                    color="secondary"
                    component={Link}
                    size="large"
                    to="/contact"
                    variant="contained"
                    target="_blank"
                  >
                    Get Started
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid
              component={Box}
              item
              lg={7}
              maxWidth={669}
              md={7}
              paddingLeft="20px"
              sm={12}
            >
              <Box maxWidth={470} className={classes.logoImg}>
                <StaticImage
                  alt="Pepper Cloud CRM Aircall Intergation"
                  placeholder="blurred"
                  src="../images/v2/bannerImage2x.png"
                  className={classes.smeSupportImg}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Content*/}
      <Box
        pb={2}
        pt={{ sm: 12, xs: 12 }[width] || 12}
        textAlign="center"
        className={classes.backgroundWhite}
      >
        <Box
          alignItems="center"
          component={Container}
          display="flex !important"
          flexDirection="column"
          pt={2}
          mt={0}
          // my={{ md: 4 }}
          textAlign="center"
        >
          <Box
            className={classes.listHeading}
            textAlign="center"
            alignItems="center"
          >
            <HeaderTypography
              component="h2"
              fontSize={35}
              fontWeight={600}
              maxWidth={572}
              alignItems="center"
              mb={3}
              mt={0}
              textAlign="center"
            >
              Seamless voice communication with Aircall-integrated CRM
            </HeaderTypography>
          </Box>
          <Box
            alignItems="center"
            container
            justifyContent="center"
            fontSize={18}
            sm={12}
            md={12}
            maxWidth={849}
          >
            Experience the power of a unified platform and elevate your sales
            and customer service with the robust telephony capabilities of
            Aircall and CRM integration.
          </Box>

          <Box
            alt="VoIP CRM Integration"
            component={"img"}
            maxWidth="100%"
            src={singaporeSmeSalesCRMImg}
            className={classes.singaporeSmeSalesCRMImg}
          />
        </Box>
      </Box>

      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={{ xs: 2, sm: 4, md: 6, lg: 8 }}
        textAlign="center"
      >
        <HeaderTypography component="h2" fontSize={40} fontWeight={600}>
          About Aircall
        </HeaderTypography>
        <ParagraphTypography maxWidth={933} className={classes.description}>
          Aircall is an integrated business phone solution that offers VoIP
          (Voice over IP) services designed to cater to the needs of sales and
          support teams of growing businesses.
        </ParagraphTypography>
        <Box p={{ xs: 2, sm: 2, md: 2, lg: 4 }}>
          <StaticImage
            alt="Aircall Integration "
            placeholder="blurred"
            src="../images/v2/aircall_logo2x.png"
            className={classes.aircallLogoImg}
          />
        </Box>
        <Box container display="flex" justifyContent="center" maxWidth={605}>
          <Box>
            <Grid container>
              <Box display="contents">
                {aboutAircall.map((item, i) => (
                  <Grid item key={i} lg={6} md={6} sm={6} xs={12}>
                    <ListItem alignItems="center" textAlign="center">
                      <ListItemIcon>
                        <CheckIcon className={classes.listItemIcon} />
                      </ListItemIcon>
                      <ListItemText className={classes.listItemText}>
                        {item.title}
                      </ListItemText>
                    </ListItem>
                  </Grid>
                ))}
              </Box>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box mt={4}>
                  <Button
                    className="book-demo-btn"
                    color="secondary"
                    size="large"
                    variant="contained"
                    onClick={executeScroll}
                    component="a" // Using the 'a' component to render an anchor tag
                    href="https://aircall.io/" // The URL you want to link to
                    target="_blank" // If you want the link to open in a new tab
                  >
                    Know More
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box className={classes.WhatsAppCrm}>
        <Box
          alignItems="center"
          component={Container}
          display="flex !important"
          flexDirection="column"
          pt={0}
          mt={0}
          // my={{ md: 4 }}
          textAlign="center"
        >
          <HeaderTypography
            component="h2"
            fontWeight={600}
            maxWidth={882}
            marginBottom="0"
            // mb={{ sm: 3, xs: 3 }[width] || 10}
            // mt={{ md: 3 }}
          >
            Revolutionise sales productivity with Aircall integration
          </HeaderTypography>
          <ParagraphTypography mb={6} mt={2}>
            Power up personalised communication and enhance your productivity
            with voice calls. With this CRM phone system integration, you can
            unify and streamline your communications without any hassle.
          </ParagraphTypography>
          <Grid alignItems="center" container justify="center" spacing={2}>
            <Grid item alignItems="center" md={6} sm={12} xs={12}>
              <Box pr={{ sm: 1, xs: 1 }[width] || 8}>
                <List>
                  {security.map((each) => (
                    <ListItem alignItems="center" key={each}>
                      <ListItemIcon>
                        <CheckIcon className={classes.listItemIcon} />
                      </ListItemIcon>
                      <ListItemText className={classes.listItemText}>
                        {each}
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Box
                display="flex"
                justifyContent={
                  { sm: "center", xs: "center" }[width] || "flex-end"
                }
              >
                <StaticImage
                  alt="call routing & recording in crm"
                  className={classes.cycleProcessImg}
                  placeholder="blurred"
                  src="../images/v2/bannerRight2x.png"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box className={classes.requestDemoRoot} py={10} textAlign="center">
        <Container>
          <Box alignItems="center" display="flex" justifyContent="center">
            <HeaderTypography
              color="common.white"
              fontSize={35}
              fontWeight={600}
              my={0}
              maxWidth={864}
              textAlign="center"
              component="h2"
            >
              Enhance customer communications with smarter voice call
              capabilities
            </HeaderTypography>
          </Box>
          <Box mt={6} textAlign="center">
            <WhiteButton
              color="primary"
              mb={6}
              size="large"
              variant="contained"
              to="/contact"
              component={Link}
              target="_blank"
            >
              Book a FREE Demo
            </WhiteButton>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Aircall;
